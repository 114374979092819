import { ifCluster } from "./maputils";
import * as CryptoJS from "crypto-js";
import { MapKing } from "./config";
import { getAesKey } from "./mkenv";
// 浅合并的版本
function mergeOptions(voption, initoption) {
  //原始的 voption 对象
  //默认的 initoption 对象
  // 创建一个新的对象，避免修改原始的 voption 对象
  const mergedOptions = { ...voption };

  // 遍历 initoption 的属性
  for (const key in initoption) {
    // 如果 initoption 拥有该属性，并且 voption 没有该属性，则添加到 mergedOptions
    if (initoption.hasOwnProperty(key) && !voption.hasOwnProperty(key)) {
      mergedOptions[key] = initoption[key];
    }
  }

  return mergedOptions;
}

// 深度合并的版本 (如果属性值是对象，则递归合并)
function deepMergeOptions(voption, initoption) {
  const mergedOptions = { ...voption };

  for (const key in initoption) {
    if (initoption.hasOwnProperty(key)) {
      if (
        typeof initoption[key] === "object" &&
        !Array.isArray(initoption[key]) &&
        typeof voption[key] === "object" &&
        !Array.isArray(voption[key])
      ) {
        // 如果属性值是对象，则递归合并
        mergedOptions[key] = deepMergeOptions(
          voption[key] || {},
          initoption[key]
        );
      } else if (!voption.hasOwnProperty(key)) {
        mergedOptions[key] = initoption[key];
      }
    }
  }

  return mergedOptions;
}
//前端解码，暴露key和iv，太过危险，不建议使用
function verifyToken(token) {
  //暂时测试返回
  if (token == "mk_hk") {
    return ' {"iss":"MapKing","uid":"mk_client","baselayers":"mk_hk","iat":1735894607}';
  }
  if (token == "mk_gba") {
    return ' {"iss":"MapKing","uid":"mk_client","baselayers":"mk_gba","iat":1735894607}';
  }
  if (token == "mk_sgmy") {
    return ' {"iss":"MapKing","uid":"mk_client","baselayers":"mk_sgmy","iat":1735894607}';
  }
  try {
    //const aes_key = aes.key;
    //alert("token:" + token);
    const aes_key = getAesKey();
    const token_iv = CryptoJS.enc.Base64.parse(token)
      .toString(CryptoJS.enc.Utf8)
      .split("-");

    //console.log(token_iv + "====key===" + aes_key);
    if (token_iv.length != 2) {
      return false;
    }
    //alert("aes_key:" + aes_key);
    const aes_encrypted = token_iv[0];

    const aes_iv = CryptoJS.enc.Latin1.parse(atob(token_iv[1]));

    const aes_decrypted = CryptoJS.AES.decrypt(
      //如果服务端返回的base64字符串，需要先解码
      //CryptoJS.enc.Base64.parse(aes_encrypted).toString(CryptoJS.enc.Utf8),
      //如果服务端直接返回的加密字符串，不需要解码
      aes_encrypted,
      CryptoJS.enc.Utf8.parse(aes_key),
      {
        iv: aes_iv,
        mode: CryptoJS.mode.CBC, //要与php端一致
      }
    );
    //alert("aes_decrypted:" + CryptoJS.enc.Utf8.stringify(aes_decrypted));
    //console.log(aes_decrypted); //解码后是一个wordArray数组
    //console.log("JS MapAPI: = ", CryptoJS.enc.Utf8.stringify(aes_decrypted)); //把二进制对象转为utf8字符串：这是一个测试AES加密
    //console.log(token_iv);
    //return true;
    return CryptoJS.enc.Utf8.stringify(aes_decrypted);
  } catch (error) {
    return false;
  }
}
async function verifyTokenByWeb(token) {
  //暂时测试返回
  if (token == "mk_hk") {
    return ' {"iss":"MapKing","uid":"mk_client","baselayers":"mk_hk","iat":1735894607}';
  }
  if (token == "mk_gba") {
    return ' {"iss":"MapKing","uid":"mk_client","baselayers":"mk_gba","iat":1735894607}';
  }
  if (token == "mk_sgmy") {
    return ' {"iss":"MapKing","uid":"mk_client","baselayers":"mk_sgmy","iat":1735894607}';
  }
  try {
    const response = await fetch(MapKing.mk_api_url + "/decodetoken", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: token }),
    });
    const data = await response.json();
    //console.log("解密后的数据:", JSON.stringify(data.token), response.status);
    if (response.status == 200 && data.code == 200)
      return JSON.stringify(data.token);
    else return false;
  } catch (error) {
    //console.error("错误:", error);
    return false; // 出错时从函数返回
  }
}
export {
  mergeOptions,
  deepMergeOptions,
  ifCluster,
  verifyToken,
  verifyTokenByWeb,
};
