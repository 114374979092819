import { mergeOptions } from "../utils";
import VectorLayer from "ol/layer/Vector";

export default class MK_VectorLayer {
  constructor(option = {}) {
    this.option = option;
    //console.log("MK_VectorLayer");
  }

  createVectorLayer(voption = {}) {
    const default_voption = {
      title: "Vector Layer Title",
      visible: true,
      //opacity: 1,
      //minZoom: 0,
      //maxZoom: undefined,
      //source: null,
      //style: null,
      //declutter: false,
    };
    const vlayer_option = mergeOptions(voption, default_voption);
    const vectorlayer = new VectorLayer(vlayer_option);
    //vectorlayer.setSource(this.def_source("hk_mtr_stations"));
    //vectorlayer.setStyle(this.def_style("hk_mtr_stations"));
    return vectorlayer;
  }
}
