import TileLayer from "ol/layer/Tile";
import { XYZ } from "ol/source";

const basemapAPI =
  "https://mapapi.geodata.gov.hk/gs/api/v1.0.0/xyz/basemap/WGS84/{z}/{x}/{y}.png";
const imageryAPI =
  "https://mapapi.geodata.gov.hk/gs/api/v1.0.0/xyz/imagery/WGS84/{z}/{x}/{y}.png";
const maplabelAPI_en =
  "https://mapapi.geodata.gov.hk/gs/api/v1.0.0/xyz/label/hk/en/WGS84/{z}/{x}/{y}.png";
const maplabelAPI_tc =
  "https://mapapi.geodata.gov.hk/gs/api/v1.0.0/xyz/label/hk/tc/WGS84/{z}/{x}/{y}.png";
const attributionInfo =
  '<u target="_blank" class="copyrightDiv">&copy; The Government of the Hong Kong SAR</u><div style="width:28px;height:28px;display:inline-flex;background:url(https://api.hkmapservice.gov.hk/mapapi/landsdlogo.jpg);background-size:28px;"></div>';

export default class HK_GOV_MAP {
  constructor(option = {}) {
    this.option = option;
    //console.log(basemapAPI);
  }
  get_hk_gov_basemap() {
    const hk_gov_basemap = new TileLayer({
      title: "GeoInfo Map",
      visible: false,
      opacity: 0.5,
      source: new XYZ({
        //projection: hkproj,
        //tileGrid: tileGrid,
        url: basemapAPI,
      }),
    });
    return hk_gov_basemap;
  }
  get_hk_gov_imagery() {
    const hk_gov_imagery = new TileLayer({
      title: "Satellite",
      visible: false,
      opacity: 0.8,
      source: new XYZ({
        //projection: hkproj,
        //tileGrid: tileGrid,
        url: imageryAPI,
      }),
    });
    return hk_gov_imagery;
  }
  get_hk_gov_maplabel_en() {
    const hk_gov_maplabel_en = new TileLayer({
      title: "Map Label (EN)",
      visible: false,
      opacity: 1,
      source: new XYZ({
        //projection: hkproj,
        //tileGrid: tileGrid,
        url: maplabelAPI_en,
      }),
    });
    return hk_gov_maplabel_en;
  }
  get_hk_gov_maplabel_tc() {
    const hk_gov_maplabel_tc = new TileLayer({
      title: "Map Label (TC)",
      visible: true,
      opacity: 1,
      source: new XYZ({
        attributions: "<a href='#'>&copy; HK GOV MAP</a>",
        //projection: hkproj,
        //tileGrid: tileGrid,
        url: maplabelAPI_tc,
      }),
    });
    return hk_gov_maplabel_tc;
  }
}
