import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";
import { MapKing } from "../config";

export default class MK_BaseMap {
  constructor(option = {}) {
    this.option = option;
    const attributions =
      '<a href="https://www.mapking.com" target="_blank">&copy; MapKing</a> ';

    //为了可以动态修改geoserver url, 例如在地图初始化时传入gs_url参数覆盖默认的geoserver地址
    let gs_url = MapKing[this.option.bgmap].mk_geoserver_url;
    if (this.option.gs_url) gs_url = this.option.gs_url;

    const mk_basemap = new TileLayer({
      title: "MapKing MY BaseMap",
      visible: true,
      //minResolution: 1, // to display the visible between max and min resolution
      //maxResolution: 100,
      source: new TileWMS({
        attributions: attributions,
        url: gs_url + "/wms",
        params: {
          FORMAT: "image/png",
          CRS: "EPSG:4326",
          VERSION: "1.1.1",
          LAYERS: MapKing[this.option.bgmap].mk_basemap,
          exceptions: "application/vnd.ogc.se_inimage",
          TILED: "true",
        },
        serverType: "geoserver",
      }),
    });
    return mk_basemap;
  }
}
/*
const mk_my_basemap = new TileLayer({
  title: "MapKing MY BaseMap",
  visible: true,
  //minResolution: 1,           // to display the visible between max and min resolution
  maxResolution: 20,
  source: new TileWMS({
    attributions: attributions,
    url: MapKing["mk_gba"].mk_geoserver_url + "/wms",
    params: {
      FORMAT: "image/png",
      CRS: "EPSG:4326",
      VERSION: "1.1.1",
      LAYERS: "my_webmap:my_basemap",
      exceptions: "application/vnd.ogc.se_inimage",
      TILED: "true",
    },
    serverType: "geoserver",
  }),
});
export default mk_my_basemap;
*/
