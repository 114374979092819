import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
import { bbox } from "ol/loadingstrategy";

function vectorsource(layer_name, mkoption) {
  const projection = mkoption.projection;
  const mk_geoserver_url = mkoption.mk_geoserver_url;

  const vectorsource = new VectorSource({
    format: new GeoJSON({
      projection: projection,
      geometryName: "geom",
    }),
    url: function (extent) {
      return (
        mk_geoserver_url +
        "/wfs?" +
        "SERVICE=WFS&VERSION=1.1.0&REQUEST=GetFeature&TYPENAME=" +
        layer_name +
        "&" +
        "OUTPUTFORMAT=application/json&srsname=EPSG:4326&" +
        "bbox=" +
        extent.join(",") +
        ",EPSG:4326"
      );
    },
    strategy: bbox,
  });
  return vectorsource;
}

export { vectorsource };
