import Style from "ol/style/Style";
import Cluster from "ol/source/Cluster";
import {
  set_iconStyle,
  set_textStyle,
  set_label,
  polygon_style,
} from "./vecotrstyle";
import { vectorsource } from "./vectorsource";
import { MapKing } from "../config";

/*************hk_mtr_stations_config************** */
//demo
/*
const hk_mtr_stations_style = function (feature, resolution) {
  return new Style({
    image: hk_mtr_stations_icon,
    text: new Text({
      text:
        feature.get("name_e").replace("MTR ", "").replace(" Station", "") +
        " " +
        feature.get("name_c").replace("港鐵", "").replace("站", ""),
      font: "12px Calibri,sans-serif",
      fill: new Fill({ color: "#000" }),
      stroke: new Stroke({ color: "#fff", width: 2 }),
      offsetX: 0,
      offsetY: 0,
    }),
  });
};
*/
//demo
const hk_mtr_stations_icon = set_iconStyle(
  "MTR_logo_square",
  0.5,
  0.5,
  0.15,
  100
);
const hk_mtr_stations_text = set_textStyle("", 0, 0);
const hk_mtr_stations_style = function (feature, resolution) {
  return new Style({
    image: hk_mtr_stations_icon,
    text: hk_mtr_stations_text,
  });
};
const hk_mtr_stations_source = vectorsource(
  "mk_webmap:hk_mtr_stations",
  MapKing["mk_hk"]
);
const hk_mtr_stations_config = {
  title: "HK MTR Stations",
  visible: true,
  //opacity: 0,
  minZoom: 28,
  //maxZoom: 22,
  //source: def_source("hk_mtr_stations"),
  style: hk_mtr_stations_style,
  source: hk_mtr_stations_source,
  declutter: false,
};
/*************hk_mtr_stations_config***************/
/*************hk_mtr_exits_config******************/
//demo
/*
const hk_mtr_exits_style = function (feature, resolution) {
  return new Style({
    image: hk_mtr_exits_icon,
    text: new Text({
      text: set_label([feature.get("exit_code")]),
      offsetX: 0,
      offsetY: -2.5,
      // ...其他文本样式...  // 添加你需要的字体、颜色、描边等样式
      font: '12px Calibri,sans-serif',
      fill: new Fill({ color: '#000' }),
      stroke: new Stroke({ color: '#fff', width: 2 }),
    }),
  });
};
*/
//demo
const hk_mtr_exits_icon = set_iconStyle("MTR_logo_square", 0.5, 0.5, 0.1, 100);
const hk_mtr_exits_style = function (feature, resolution) {
  const hk_mtr_exits_text = set_textStyle(
    set_label([feature.get("exit_code")]),
    0,
    -2.5
  );
  return new Style({
    image: hk_mtr_exits_icon,
    text: hk_mtr_exits_text,
  });
};

const hk_mtr_exits_source = vectorsource(
  "mk_webmap:hk_mtr_exits",
  MapKing["mk_hk"]
);
const hk_mtr_exits_config = {
  title: "HK MTR Exits",
  visible: true,
  //opacity: 0,
  minZoom: 34,
  //maxZoom: 22,
  source: hk_mtr_exits_source,
  style: hk_mtr_exits_style,
  declutter: false,
  renderBuffer: 0,
};
/*************hk_mtr_exits_config******************/
/*************hk_business_listing_config***********/
const hk_business_listing_source = vectorsource(
  "mk_webmap:hk_business_listing",
  MapKing["mk_hk"]
);
const hk_business_listing_cluster = new Cluster({
  distance: 35,
  source: hk_business_listing_source,
});
const hk_business_listing_style = function (feature, resolution) {
  return new Style({});
};
const hk_business_listing_config = {
  id: "clusterBusinessListing",
  title: "hk_business_listing",
  visible: true,
  //opacity: 0,
  minZoom: 34,
  //maxZoom: 22,
  source: hk_business_listing_cluster,
  //style: hk_business_listing_style,
  declutter: true,
};
/*************hk_business_listing_config***********/

/*************hk_shopping_malls_config***********/
const hk_shopping_malls_icon = set_iconStyle(
  "shopping_marker",
  0.5,
  46,
  0.75,
  100
);
const hk_shopping_malls_style = function (feature, resolution) {
  const label_texts = [feature.get("name_e"), feature.get("name_c")];
  const hk_shopping_malls_text = set_textStyle(set_label(label_texts), -13, 0);

  return new Style({
    image: hk_shopping_malls_icon,
    text: hk_shopping_malls_text,
  });
};

const hk_shopping_malls_source = vectorsource(
  "mk_webmap:hk_shopping_malls",
  MapKing["mk_hk"]
);
const hk_shopping_malls_config = {
  title: "hk_shopping_malls",
  visible: true,
  //opacity: 0,
  minZoom: 34,
  //maxZoom: 22,
  source: hk_shopping_malls_source,
  style: hk_shopping_malls_style,
  declutter: true,
};
/*************hk_shopping_malls_config***********/

/*************hk_district_councils_config***********/
const hk_district_councils_source = vectorsource(
  "mk_webmap:hk_district_councils",
  MapKing["mk_hk"]
);
const hk_district_councils_style = function (feature, resolution) {
  var label_texts = [feature.get("name_e"), feature.get("name_tc")];
  polygon_style.setText(set_textStyle(set_label(label_texts), 0, 0));
  return polygon_style;
};

const hk_district_councils_config = {
  title: "hk_district_councils",
  visible: true,
  //opacity: 0,
  //minZoom: 34,
  //maxZoom: 22,
  source: hk_district_councils_source,
  style: hk_district_councils_style,
  declutter: true,
};
/*************hk_district_councils_config***********/
/*************hk_common_districts_config***********/
const hk_common_districts_source = vectorsource(
  "mk_webmap:hk_common_districts",
  MapKing["mk_hk"]
);
var hk_common_districts_style = function getText(feature, resolution) {
  var label_texts = [feature.get("name_e"), feature.get("name_tc")];
  polygon_style.setText(set_textStyle(set_label(label_texts), 0, 0));
  return polygon_style;
};
const hk_common_districts_config = {
  title: "hk_common_districts",
  visible: true,
  //opacity: 0,
  minZoom: 38,
  //maxZoom: 22,
  source: hk_common_districts_source,
  style: hk_common_districts_style,
  declutter: true,
};
/*************hk_common_districts_config***********/
/*************gba_city_poi_config***********/
const gba_city_poi_source = vectorsource(
  "gba_webmap:gba_city_poi",
  MapKing["mk_gba"]
);
const gba_city_poi_cluster = new Cluster({
  distance: 35,
  source: gba_city_poi_source,
});
const gba_city_poi_style = function (feature, resolution) {
  return new Style({});
};
const gba_city_poi_config = {
  id: "gba_city_poi",
  title: "gba_city_poi",
  visible: true,
  //opacity: 0,
  minZoom: 34,
  //maxZoom: 22,
  source: gba_city_poi_cluster,
  //style: hk_business_listing_style,
  declutter: true,
};
/*************gba_city_poi_config***********/

export {
  hk_mtr_stations_config,
  hk_mtr_exits_config,
  hk_business_listing_config,
  hk_shopping_malls_config,
  hk_district_councils_config,
  hk_common_districts_config,
  gba_city_poi_config,
};
