import "../css/ol.css";
import "../css/403.css";
import "../css/mapking.css";
import { Map, View } from "ol";

import Overlay from "ol/Overlay.js";
import OSM from "ol/source/OSM.js";
import TileLayer from "ol/layer/Tile.js";
import VectorSource from "ol/source/Vector";

//MapKing的配置文件，需要引入
import { MapKing } from "./config";
import { mousePositionControl, zoomControl, zoomSlider } from "./control";

import MK_BaseMap from "./layers/baselayer";
import HK_GOV_MAP from "./layers/hkgovlayer";
//import OSM_MAP from "./layers/osmlayer";
//import RES_MAP from "./layers/reslayer";
import MK_VectorLayer from "./layers/vectorlayer";

import {
  hk_mtr_stations_config,
  hk_mtr_exits_config,
  hk_business_listing_config,
  hk_shopping_malls_config,
  hk_district_councils_config,
  hk_common_districts_config,
  //gba
  gba_city_poi_config,
} from "./layers/vectorconfig";

import { features_by_hover } from "./control/features_by_hover";
import { features_by_click } from "./control/features_by_click";

export default class MK_MAP {
  constructor(option = {}) {
    this.option = option;
    this.layers = [];
  }
  initMap() {
    const mk_mapview = new View(MapKing[this.option.bgmap].mk_view);
    const osm = new TileLayer({
      source: new OSM(),
      opacity: 0.8,
    });
    const mk_basemap = new MK_BaseMap(this.option);

    //默认popup控件
    const feature_container = document.getElementById("feature_container");
    const feature_click_container = document.getElementById(
      "feature_click_container"
    );
    const closer = document.getElementById("popup-closer");

    const overlay = new Overlay({
      id: "feature_hover_container",
      element: feature_container,
      autoPan: {
        animation: {
          duration: 250,
        },
      },
    });
    const overlay_click = new Overlay({
      id: "feature_click_container",
      element: feature_click_container,
      autoPan: {
        animation: {
          duration: 250,
        },
      },
    });
    // closer.onclick = function () {
    //   overlay_click.setPosition(undefined);
    //   closer.blur();
    //   return false;
    // };
    closer.addEventListener("click", function () {
      overlay_click.setPosition(undefined);
      closer.blur();
      return false;
    });

    const mk_map = new Map({
      target: "map",
      layers: [],
      overlays: [overlay, overlay_click],
      view: mk_mapview,
    });

    mk_map.on("pointermove", (evt) => features_by_hover(evt, mk_map, overlay));
    mk_map.on("click", (evt) => features_by_click(evt, mk_map, overlay_click));
    //计算分辨率
    //const res_map = new RES_MAP();
    //添加OSM图层
    //const osm_map = new OSM_MAP();

    if (this.option.bgmap === "mk_gba" || this.option.bgmap === "mk_sgmy") {
      mk_map.addLayer(osm);
      //mk_map.addLayer(osm_map);
    }

    //这里是MapKing的底图
    mk_map.addLayer(mk_basemap);

    //添加HK_GOV_MAP图层
    if (
      this.option.bgmap === "mk_hk" ||
      this.option.bgmap === "mk_gba" ||
      this.option.bgmap === "mk_res"
    ) {
      const hk_gov_map = new HK_GOV_MAP();
      const hk_gov_basemap = hk_gov_map.get_hk_gov_basemap();
      const hk_gov_imagery = hk_gov_map.get_hk_gov_imagery();
      const hk_gov_maplabel_en = hk_gov_map.get_hk_gov_maplabel_en();
      const hk_gov_maplabel_tc = hk_gov_map.get_hk_gov_maplabel_tc();

      mk_map.addLayer(hk_gov_basemap);
      mk_map.addLayer(hk_gov_imagery);
      mk_map.addLayer(hk_gov_maplabel_en);
      mk_map.addLayer(hk_gov_maplabel_tc);

      //添加矢量图层
      const mk_vectorlayer = new MK_VectorLayer(this.option);

      const hk_mtr_stations = mk_vectorlayer.createVectorLayer(
        hk_mtr_stations_config
      );
      mk_map.addLayer(hk_mtr_stations);

      const hk_mtr_exits =
        mk_vectorlayer.createVectorLayer(hk_mtr_exits_config);
      mk_map.addLayer(hk_mtr_exits);

      const hk_business_listing = mk_vectorlayer.createVectorLayer(
        hk_business_listing_config
      );
      mk_map.addLayer(hk_business_listing);

      const hk_shopping_malls = mk_vectorlayer.createVectorLayer(
        hk_shopping_malls_config
      );
      mk_map.addLayer(hk_shopping_malls);

      if (this.option.bgmap === "mk_gba") {
        const gba_city_poi =
          mk_vectorlayer.createVectorLayer(gba_city_poi_config);
        mk_map.addLayer(gba_city_poi);
      }

      //现在用户自定义的图层添加在这里
      const mk_client_vectorlayer = mk_vectorlayer.createVectorLayer({
        id: "mk_client_vectorlayer",
      });
      const mk_client_vectorSource = new VectorSource({});
      mk_client_vectorlayer.setSource(mk_client_vectorSource);
      mk_map.addLayer(mk_client_vectorlayer);
      this.layers["mk_client_vectorlayer"] = mk_client_vectorSource;
    }

    /*
    const hk_district_councils = mk_vectorlayer.createVectorLayer(
      hk_district_councils_config
    );
    mk_map.addLayer(hk_district_councils);

    const hk_common_districts = mk_vectorlayer.createVectorLayer(
      hk_common_districts_config
    );
    mk_map.addLayer(hk_common_districts);
*/
    //显示坐标
    mk_map.addControl(mousePositionControl);
    mk_map.addControl(zoomControl);
    mk_map.addControl(zoomSlider);

    // 可选：将初始视图调整为图层范围
    /*mk_map
      .getView()
      .fit(MapKing[this.option.bgmap].mk_view["extent"], mk_map.getSize());
      */

    mk_map.on("rendercomplete", function (e) {
      let zoomLevel = mk_map.getView().getZoom();
      //console.log("zoomLevel", zoomLevel);
      //var zoomRounded = Math.round(zoomLevel * 10) / 10;
      //console.log(zoomRounded);
      //function rad_to_deg(radians) {
      // return radians * (180 / Math.PI);
      //}
      //var map_rotation = mk_map.getView().getRotation();
      //console.log(map_rotation);
      //console.log(rad_to_deg(map_rotation));
    });
    return mk_map;
  }
}
